import {
  HStack,
  Skeleton,
  Stack,
  Tag,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { EndpointOut } from "svix";
import { EndpointApi } from "svix/dist/openapi";

import { getApiErrorCode } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useOrgSettings } from "src/hooks/common";
import { useAppSelector } from "src/hooks/store";

interface IOAuthCardProps {
  endpoint: EndpointOut;
}

export default function OAuthCard(props: IOAuthCardProps) {
  const { endpoint } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const queryClient = useQueryClient();
  const queryKey = ["endpoints", endpoint.id, "oauth"];
  const [isDeleting, setIsDeleting] = useBoolean();
  const toast = useToast();

  const { data, isLoading } = useAppQuery(queryKey, async () => {
    const sv = getSvix();
    const api = new EndpointApi(sv._configuration);
    try {
      const res = await api.v1EndpointGetOauthConfig({
        appId: user.app.id,
        endpointId: endpoint.id,
      });
      return res;
    } catch (error) {
      if (getApiErrorCode(error) === "not_found") {
        return undefined;
      }
      throw error;
    }
  });

  const { data: orgSettings } = useOrgSettings();
  const enableEndpointOAuth = orgSettings?.enableEndpointOauthConfig;

  const onDeleteOAuth = async () => {
    const sv = getSvix();
    const api = new EndpointApi(sv._configuration);

    try {
      await api.v1EndpointDeleteOauthConfig({
        appId: user.app.id,
        endpointId: endpoint.id,
      });
      queryClient.invalidateQueries(queryKey);
      setIsDeleting.off();
      toast({
        title: "OAuth Authentication Disabled",
        status: "success",
      });
    } catch (e) {
      toast({
        title: "Failed to disable OAuth Authentication",
        description: "Please try again later.",
        status: "error",
      });
    }
  };

  const isEnabled = !!data;

  if (!enableEndpointOAuth) {
    return null;
  }

  return (
    <Card title="OAuth">
      <Stack
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        spacing={4}
      >
        <Stack spacing={0}>
          <HStack>
            <Text>OAuth Authentication</Text>
            {isEnabled && (
              <Tag size="sm" colorScheme="green">
                Enabled
              </Tag>
            )}
          </HStack>
          <Text variant="caption" fontSize="sm">
            Use your own OAuth 2.0 provider to authenticate webhooks.
          </Text>
        </Stack>
        <HStack spacing={4}>
          {isEnabled && (
            <Button variant="ghost" size="sm" onClick={setIsDeleting.on}>
              Disable
            </Button>
          )}
          <Skeleton isLoaded={!isLoading}>
            <Link
              to={routeResolver.getRoute("endpoints._id.oauth", { endpId: endpoint.id })}
            >
              <Button variant="outline" size="sm">
                Configure
              </Button>
            </Link>
          </Skeleton>
        </HStack>
      </Stack>
      <ConfirmationDialog
        colorScheme="red"
        title="Disable OAuth Authentication?"
        isOpen={isDeleting}
        onCancel={setIsDeleting.off}
        onOk={onDeleteOAuth}
        labelOk="Disable"
      >
        <Text>
          Are you sure you would want to disable OAuth authentication for this endpoint?
        </Text>
      </ConfirmationDialog>
    </Card>
  );
}
