export enum StreamSinkStatus {
  ENABLED = "enabled",
  PAUSED = "paused",
  DISABLED = "disabled",
}

export interface StreamSinkOut {
  id: string;
  uid?: string;
  // FIXME STREAM_APP_PORTAL: Add config type
  type: string;
  config: any;
  status: StreamSinkStatus;
  currentIterator?: string;
  disabledReason?: string;
  createdAt: Date;
  updatedAt: Date;
  batchSize: number;
  maxWaitSecs: number;
  eventTypes?: string[];
  transformation?: string;
  format: string;
}

export interface StreamSinkIn {
  uid?: string;
  // FIXME STREAM_APP_PORTAL: Add config type
  type: string;
  config: any;
  batchSize: number;
  maxWaitSecs: number;
  eventTypes?: string[];
  transformation?: string;
  format: string;
}
