import { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Tag,
  Flex,
  Heading,
  Stack,
  Divider,
  useBoolean,
} from "@chakra-ui/react";
import { MoreVert } from "@material-ui/icons";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { EndpointUpdate, ListResponseEndpointOut, EndpointOut } from "svix";
import { EndpointApi } from "svix/dist/openapi";

import { useSearch, useSetSearch } from "@svix/common/hooks/search";
import {
  extractSvixPlayToken,
  getSvixPlayViewUrl,
  isSvixPlayUrl,
} from "@svix/common/play";
import { formatDateTime, humanize } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";
import ResourceNotFound from "@svix/common/widgets/ResourceNotFound";
import Stat from "@svix/common/widgets/Stat";

import { getSvix, SinkOut } from "src/api";
import { SinksApi } from "src/api/sinks";
import { routeResolver } from "src/App";
import EndpointDetailsTour from "src/components/Tours/EndpointDetailsTour";
import { useAppQuery } from "src/hooks/api";
import { useOrgSettings } from "src/hooks/common";
import { useAppSelector } from "src/hooks/store";
import { isEE, useLoadingManual } from "src/utils";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";
import AttemptsTable from "./AttemptsTable";
import CustomHeaders from "./CustomHeaders";
import Description from "./Description";
import EndpointStatChart, { getFormattedStats } from "./EndpointStats";
import EndpointUrl from "./EndpointUrl";
import OAuthCard from "./OAuthCard";
import Channels from "./properties/Channels";
import EndpointEventTypes from "./properties/EventTypes";
import SigningSecret from "./properties/SigningSecret";
import RateLimit from "./RateLimit";
import RecoverModal from "./RecoverModal";
import ReplayModal from "./ReplayModal";
import SendExample from "./SendExample";
import TransformationCard from "./TransformationCard";

const endpointTabs = ["overview", "testing", "advanced"];

export default function EndpointScreen({ isSink = false }: { isSink?: boolean }) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const user = useAppSelector((state) => state.auth.user)!;
  const { hideEventTypes } = useAppSelector((state) => state.embedConfig);
  const { endpId } = useParams<{ endpId: string }>();
  const queryKey = ["endpoints", endpId];
  const transformationsEnabled = useAppSelector(
    (state) => state.settings.enableTransformations
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useBoolean();
  const [showReplayModal, setShowReplayModal] = useBoolean();
  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  const { data: endpointOrSink, error } = useAppQuery(
    queryKey,
    async () => {
      if (isSink) {
        const svix = getSvix();
        const api = new SinksApi(svix);
        const res = (await api.getSink(user.app.id, endpId)) as SinkOut;
        return res;
      } else {
        const api = getSvix();
        return api.endpoint.get(user.app.id, endpId);
      }
    },
    {
      placeholderData: () =>
        queryClient
          .getQueryData<ListResponseEndpointOut>("endpoints")
          ?.data.find((d) => d.id === endpId),
    }
  );

  const endpoint: EndpointOut | undefined =
    endpointOrSink && endpointFromSink(endpointOrSink);

  const { data: stats } = useAppQuery(
    [...queryKey, "stats"],
    async () => {
      const sv = getSvix();
      const config = sv._configuration;
      const endpApi = new EndpointApi(config);
      return endpApi.v1EndpointGetStats({
        appId: user.app.id,
        endpointId: endpId,
      });
    },
    {
      enabled: !!endpoint,
    }
  );

  const { data: orgSettings } = useOrgSettings();

  const [, , setDisabled] = useLoadingManual(
    async (disabled: boolean) => {
      const api = getSvix();
      const patchedEndpoint = { ...endpoint, disabled };
      if (endpoint!.filterTypes && endpoint!.filterTypes.length === 0) {
        delete patchedEndpoint["filterTypes"];
      }

      await api.endpoint.update(user.app.id, endpId, patchedEndpoint as EndpointUpdate);
      queryClient.invalidateQueries(queryKey);
    },
    [endpId, endpoint]
  );

  const [, , deleteEndpoint] = useLoadingManual(async () => {
    const api = getSvix();
    await api.endpoint.delete(user.app.id, endpId);
    history.replace(routeResolver.getRoute("endpoints"));
  }, []);

  const tab = useSearch("tab");
  const setSearch = useSetSearch();
  const tabsIndex = Math.max(0, endpointTabs.indexOf(tab ?? "overview"));

  if (error) {
    return (
      <ResourceNotFound
        resourceName="endpoint"
        to={routeResolver.getRoute("endpoints")}
      />
    );
  }

  if (!endpoint) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <MetaTitle path={[endpoint.uid ?? humanize(endpId), "Endpoints", user.app.name]} />
      <EndpointDetailsTour />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("endpoints")}>
            Endpoints
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={endpId} uid={endpoint.uid ?? undefined} />
          {endpoint.disabled && (
            <Tag size="md" colorScheme="red" ml={2}>
              Disabled
            </Tag>
          )}
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Box>
          <Menu placement="bottom-end">
            <MenuButton as={IconButton} variant="rounded" data-cy="options-button">
              <MoreVert />
            </MenuButton>
            <MenuList data-cy="options-menu">
              {isSvixPlayUrl(endpoint.url) && !isEE && (
                <>
                  <MenuItem
                    as="a"
                    href={getSvixPlayViewUrl(extractSvixPlayToken(endpoint.url) || "")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Svix Play
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
              <MenuItem onClick={setShowRecoverModal.on}>
                Recover failed messages...
              </MenuItem>
              <MenuItem onClick={setShowReplayModal.on}>
                Replay missing messages...
              </MenuItem>
              <ReadOnlyTooltip readOnly={isReadOnly}>
                <MenuItem
                  isDisabled={isReadOnly}
                  onClick={() => setDisabled(!endpoint.disabled)}
                >
                  {endpoint.disabled ? "Enable" : "Disable"} Endpoint
                </MenuItem>
              </ReadOnlyTooltip>
              <ReadOnlyTooltip readOnly={isReadOnly}>
                <MenuItem
                  isDisabled={isReadOnly}
                  textColor="text.danger"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete
                </MenuItem>
              </ReadOnlyTooltip>
            </MenuList>
          </Menu>
        </Box>
      </PageToolbar>
      <Grid
        gridTemplateColumns={{
          sm: "minmax(0, 1fr)",
          md: "minmax(0, 3fr) minmax(240px, 1fr)",
        }}
        gap={8}
      >
        <Tabs
          variant="enclosed"
          index={tabsIndex}
          onChange={(i) => setSearch("tab", endpointTabs[i])}
        >
          {isSink ? (
            <Heading mb={6} as="h1" size="sm" isTruncated>
              {endpoint.url}
            </Heading>
          ) : (
            <EndpointUrl endpoint={endpoint} />
          )}
          <TabList>
            <Tab name="overview">Overview</Tab>
            <Tab name="testing">Testing</Tab>
            <Tab name="advanced">Advanced</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack spacing={4} id="overview-tab-content">
                <Description endpoint={endpoint} />
                {isSink && endpointOrSink && (
                  <SinkConfiguration sink={endpointOrSink as SinkOut} />
                )}
                <Card title="Attempt Delivery Stats" mt={6}>
                  <EndpointStatChart
                    stats={getFormattedStats(stats)}
                    isLoading={!stats}
                  />
                </Card>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Box id="testing-tab-content">
                <SendExample endpoint={endpoint} />
              </Box>
            </TabPanel>
            <TabPanel>
              <Stack spacing={4} id="advanced-tab-content">
                <RateLimit endpoint={endpoint} />
                <CustomHeaders endpoint={endpoint} />
                {transformationsEnabled && <TransformationCard endpoint={endpoint} />}
                <OAuthCard endpoint={endpoint} />
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Stack spacing={4}>
          <Stat name="Creation Date">{formatDateTime(endpoint.createdAt)}</Stat>
          <Divider />
          <Stat name="Last Updated">{formatDateTime(endpoint.updatedAt)}</Stat>
          {orgSettings?.enableChannels && (
            <>
              <Divider />
              <Channels endpoint={endpoint} queryKey={queryKey} />
            </>
          )}
          <Divider />
          {!hideEventTypes && (
            <>
              <EndpointEventTypes endpoint={endpoint} queryKey={queryKey} />
              <Divider />
            </>
          )}
          <SigningSecret />
        </Stack>
      </Grid>

      <AttemptsTable endpoint={endpoint} />

      <RecoverModal
        isOpen={showRecoverModal}
        onClose={setShowRecoverModal.off}
        endpoint={endpoint}
      />
      <ReplayModal
        isOpen={showReplayModal}
        onClose={setShowReplayModal.off}
        endpoint={endpoint}
      />
      <ConfirmationDialog
        title="Deletion confirmation"
        isOpen={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onOk={deleteEndpoint}
        labelOk="Delete"
        colorScheme="red"
      >
        Are you sure you would like to permanently delete this endpoint?
      </ConfirmationDialog>
    </>
  );
}

// FIXME(#8525): Add support for updating these values
const SinkConfiguration = ({ sink }: { sink: SinkOut }) => {
  return (
    <Card maxH={460} title="Configuration">
      {sink.type === "rabbitMQ" && (
        <Stack spacing={4}>
          <Stat name="AMQPS URI">
            <code>{sink.uri}</code>
          </Stat>
          <Stat name="Routing Key">
            <code>{sink.routingKey}</code>
          </Stat>
        </Stack>
      )}

      {sink.type === "sqs" && (
        <Stack spacing={4}>
          <Stat name="Region">
            <code>{sink.region}</code>
          </Stat>
          <Stat name="Queue DSN">
            <code>{sink.queueDsn}</code>
          </Stat>
          <Stat name="Access Key">{sink.accessKey}</Stat>
          <Stat name="Secret Key">{sink.secretKey}</Stat>
        </Stack>
      )}
    </Card>
  );
};

const getSinkUrl = (sink: SinkOut) => {
  switch (sink.type) {
    case "rabbitMQ":
      return sink.uri;
    case "sqs":
      return sink.queueDsn;
    case "http":
      return sink.url;
    default:
      return "";
  }
};

const endpointFromSink = (endp: EndpointOut | SinkOut): EndpointOut => {
  if ("type" in endp) {
    return {
      ...endp,
      url: getSinkUrl(endp),
    };
  }
  return endp;
};
