import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { MoreVert } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { humanize } from "@svix/common/utils";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";

import { StreamSinkOut, StreamSinkStatus } from "src/api/streamSinks";
import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import { SinkDetails } from "./SinkDetails";

const exampleSink: StreamSinkOut = {
  id: "sink_2yVxRGfers6LVJtixrnmD",
  type: "amazonS3",
  config: {
    bucket: "asdfsdfsa",
    accessKeyId: "asdfasdfasd",
    region: "asdfasdf",
  },
  status: StreamSinkStatus.ENABLED,
  currentIterator: undefined,
  disabledReason: undefined,
  createdAt: new Date("2024-12-13T15:38:18.225Z"),
  updatedAt: new Date("2025-01-13T17:55:39.318297Z"),
  batchSize: 100,
  maxWaitSecs: 10,
  eventTypes: [],
  format: "json",
};

export default function StreamSink() {
  const history = useHistory();
  const toast = useToast();
  const user = useAppSelector((state) => state.auth.user)!;

  // FIXME STREAM_APP_PORTAL: Replace with real API call
  const sinkId = "sink_2yVxRGfers6LVJtixrnmD";
  const queryKey = ["sinks", sinkId];
  const { data: sink } = useAppQuery(queryKey, async () => {
    return exampleSink;
  });

  const { data: transformation } = useAppQuery(
    ["stream", "sinks", sinkId, "transformation"],
    async () => {
      // FIXME STREAM_APP_PORTAL: Replace with real API call
      return {
        code: "console.log(event)",
        enabled: true,
      };
    }
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useBoolean(false);
  const deleteSink = async () => {
    // FIXME STREAM_APP_PORTAL: Implement
    toast({
      title: "Sink deleted",
      description: "The sink has been deleted successfully",
      status: "success",
    });
    history.push(routeResolver.getRoute("stream.sinks"));
  };

  if (!sink || !transformation) {
    // FIXME STREAM_APP_PORTAL: Add skeleton for loading indicator
    return <LoadingIndicator />;
  }

  return (
    <>
      <MetaTitle path={[sink.uid ?? humanize(sinkId), "Sinks", user.app.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("stream.sinks")}>
            Sinks
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={sinkId} uid={sink.uid ?? undefined} />
        </Breadcrumbs>

        <Spacer />

        <Menu placement="bottom-end">
          <MenuButton as={IconButton} variant="rounded" data-cy="options-button">
            <MoreVert />
          </MenuButton>
          <MenuList data-cy="options-menu">
            <MenuItem textColor="text.danger" onClick={setDeleteDialogOpen.on}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </PageToolbar>

      <ConfirmationDialog
        title="Deletion confirmation"
        isOpen={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen.off()}
        onOk={deleteSink}
        labelOk="Delete"
        colorScheme="red"
      >
        Are you sure you would like to permanently delete this sink?
      </ConfirmationDialog>

      <SinkDetails
        sink={sink}
        queryKey={queryKey}
        // FIXME STREAM_APP_PORTAL: Implement
        transformation={{
          ...transformation,
          // eslint-disable-next-line no-console
          onUpdateTransformation: async () => console.log("update transformation"),
        }}
        // FIXME STREAM_APP_PORTAL: Implement
        // eslint-disable-next-line no-console
        onUpdateSink={async () => exampleSink}
      />
    </>
  );
}
